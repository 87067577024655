/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import CardsFooter from "../components/Footers/CardsFooter.js";

class ContactUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          sent: '',
          from: '',
          fullname: '',
          message: ''
        }
    }

    handleFromChanged(event) {
        this.setState({
          from: event.target.value
        });
      }

    handleFullnameChanged(event) {
        this.setState({
          fullname: event.target.value
        });
      }

    handleMessageChanged(event) {
        this.setState({
          message: event.target.value
        });
      }

    handleButtonClicked() {
        var from = this.state.from;
        var fullname = this.state.fullname;
        var message = this.state.message;

        let details = {
            'from': from,
            'text': fullname + " -> " + message
        };

        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log(  "----->>>>" + formBody );

        fetch('/contact', {
            method: 'POST',
            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
            body: formBody,
        }).then((response) => response.json())
          .then((responseData) => {
              console.log(responseData);
              this.setState({ sent: 'success', from: '', message: '', text: '' });
              
          });
          this.setState({ sent: 'success' });
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }

  render() {
    if ( this.state.sent == "" ) {
        return (
          <>
            <MainNavbar />
            <main ref="main">
              <section className="section section-shaped section-lg">
                <div className="shape shape-style-1 bg-gradient-default">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                <Container className="pt-lg-7">
                  <Row className="justify-content-center">
                    <Col lg="5">
                       <Card className="bg-gradient-secondary shadow">

                           <CardBody className="p-lg-5">
                             <h4 className="mb-1">Contact us</h4>
                             <p className="mt-0">
                               Your project is very important to us.
                             </p>
                             <FormGroup
                               className={classnames("mt-5", {
                                 focused: this.state.nameFocused
                               })}
                             >
                               <InputGroup className="input-group-alternative">
                                 <InputGroupAddon addonType="prepend">
                                   <InputGroupText>
                                     <i className="ni ni-user-run" />
                                   </InputGroupText>
                                 </InputGroupAddon>
                                 <Input
                                   placeholder="Your name"
                                   type="text"
                                   name="fullname"
                                   value={this.state.fullname} onChange={this.handleFullnameChanged.bind(this)}
                                   onFocus={e => this.setState({ nameFocused: true })}
                                   onBlur={e => this.setState({ nameFocused: false })}
                                 />
                               </InputGroup>
                             </FormGroup>
                             <FormGroup
                               className={classnames({
                                 focused: this.state.fromFocused
                               })}
                             >
                               <InputGroup className="input-group-alternative">
                                 <InputGroupAddon addonType="prepend">
                                   <InputGroupText>
                                     <i className="ni ni-email-83" />
                                   </InputGroupText>
                                 </InputGroupAddon>
                                 <Input
                                   placeholder="Email address"
                                   type="email"
                                   name="from"
                                   value={this.state.from} onChange={this.handleFromChanged.bind(this)}
                                   onFocus={e => this.setState({ fromFocused: true })}
                                   onBlur={e => this.setState({ fromFocused: false })}
                                 />
                               </InputGroup>
                             </FormGroup>
                             <FormGroup className="mb-4">
                               <Input
                                 className="form-control-alternative"
                                 cols="80"
                                 name="message"
                                 placeholder="Type a message..."
                                 value={this.state.message} onChange={this.handleMessageChanged.bind(this)}
                                 rows="4"
                                 type="textarea"
                               />
                             </FormGroup>
                             <div>
                               <Button
                                 block
                                 className="btn-round"
                                 color="default"
                                 size="lg"
                                 type="submit"
                                 onClick={this.handleButtonClicked.bind(this)}
                               >
                                 Send Message
                               </Button>
                             </div>
                           </CardBody>

                         </Card>
                    </Col>
                  </Row>
                </Container>
              </section>
            </main>
            <CardsFooter />
          </>
        )
    } else {
        return (
         <>
         <MainNavbar />
         <main ref="main">
           <section className="section section-shaped section-lg">
              <Alert variant="default">
                You message was sent successfully, someone will be in touch.
              </Alert>
           </section>
          </main>
          </>
        )
    }

  }
}

export default ContactUs;
